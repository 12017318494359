import React from 'react';
import { graphql } from 'gatsby';
import { SanityHiltonVideo } from 'graphql-types';
import { HiltonEducationIndexPageTemplate } from '../../components/HiltonEducation/hilton-education-index-page-template';

interface HiltonVideoPageProps {
  data: {
    videos: {
      edges: {
        node: SanityHiltonVideo;
      }[];
    };
  };
  pageContext: {
    totalNumberOfPages: number;
    pathPrefix: string;
  };
}

const HiltonEducationVideoPaginationTemplate = ({
  data,
  pageContext
}: HiltonVideoPageProps) => {
  const videos = data?.videos?.edges.map(edge => edge.node);
  const { totalNumberOfPages, pathPrefix } = pageContext;

  return (
    <HiltonEducationIndexPageTemplate
      pathPrefix={pathPrefix}
      posts={videos}
      totalNumberOfPages={totalNumberOfPages}
      variant="video"
    />
  );
};

export const query = graphql`
  query HiltonVideoIndexPaginationQuery($limit: Int, $skip: Int) {
    videos: allSanityHiltonVideo(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: publishedDate }
      filter: {
        slug: { current: { ne: null } }
        image: { asset: { id: { ne: null } } }
      }
    ) {
      edges {
        node {
          ...HiltonVideoCardInfo
        }
      }
    }
  }
`;

export default HiltonEducationVideoPaginationTemplate;
